let banner_index = 0;
let inline_banner_index = 0;

function bannerRotate() {

    // rotate top banner
    let $banner = $('.stuff-top .banner-link');
    banner_index ++;
    if (banner_index >= $banner.length) {
        banner_index = 0;
    }

    $banner.removeClass('active').slice(banner_index, banner_index + 1).addClass('active');

    // rotate inline banner
    let $inline_banner = $('.sterling');
    inline_banner_index ++;
    if (inline_banner_index >= $inline_banner.length) {
        inline_banner_index = 0;
    }

    $inline_banner.removeClass('active').slice(inline_banner_index, inline_banner_index + 1).addClass('active');

    setTimeout(bannerRotate, window.banner_rotation);
}

setTimeout(bannerRotate, window.banner_rotation);