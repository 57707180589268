// financing.js

require('./jSignature.min');
require('../../node_modules/jquery.inputmask/dist/jquery.inputmask.bundle');

(function ($) {

  
    $('#financing-code').on('submit', function(event) {
        event.preventDefault();
        // show modal
        if ($('#private').val().length) {
            $('#private-agree-modal').modal('show');
        }

    });

    $('#agree').on('keyup', function() {
        if ($(this).val().toLowerCase() == 'i agree') {
            // $('#agree-button').prop('disabled', false);
        }
    });

    $('#agree-button').on('click', function() {
        const code = $('#private').val();
        window.location = `/financing/${code}`;
    });

    //set steps to disabled
    $( window ).load(function() {
    $(".steps" ).attr( "disabled", true );
    });


  /**
   * @src https://codepen.io/joe-watkins/pen/XKEqQV
   * @name Multi-step form - WIP
   * @description Prototype for basic multi-step form
   * @deps jQuery, jQuery Validate
   */

  var app = {

    init: function(){
      this.cacheDOM();
      this.nextButton();
      this.prevButton();
      this.validateForm();
      this.startOver();
      this.editForm();
      this.killEnterKey();
      this.handleStepClicks();
      this.conditionalRequires();
      this.sameAsHomeAddress();
    },

    cacheDOM: function(){
      if($(".multi-step-form").size() === 0){ return; }
      this.$formParent = $(".multi-step-form");
      this.$form = this.$formParent.find("form");
      this.$formStepParents = this.$form.find("fieldset");

      this.$nextButton = this.$form.find(".btn-next");
      this.$prevButton = this.$form.find(".btn-prev");
      this.$editButton = this.$form.find(".btn-edit");
      this.$resetButton = this.$form.find("[type='reset']");

      this.$stepsParent = $(".steps");
      this.$steps = this.$stepsParent.find("button");
    },

    htmlClasses: {
      activeClass: "active",
      hiddenClass: "hidden",
      visibleClass: "visible",
      editFormClass: "edit-form",
      animatedVisibleClass: "animated fadeIn",
      animatedHiddenClass: "animated fadeOut",
      animatingClass: "animating"
    },

    nextButton: function(){

      this.$nextButton.on("click", function(e){

        e.preventDefault();

        // grab current step and next step parent
        var $this = $(this),
          currentParent = $this.closest("fieldset"),
          nextParent = currentParent.next();

        // if the form is valid hide current step
        // trigger next step
        if(app.checkForValidForm()){
          currentParent.removeClass(app.htmlClasses.visibleClass);
          app.showNextStep(currentParent, nextParent);
        }

      });
    },

    prevButton: function(){

      this.$prevButton.on("click", function(e){

        e.preventDefault();

        // grab current step parent and previous parent
        var $this = $(this),
          currentParent = $(this).closest("fieldset"),
          prevParent = currentParent.prev();

        // hide current step and show previous step
        // no need to validate form here
        currentParent.removeClass(app.htmlClasses.visibleClass);
        app.showPrevStep(currentParent, prevParent);

      });
    },

    showNextStep: function(currentParent,nextParent){

      // hide previous parent
      currentParent
        .addClass(app.htmlClasses.hiddenClass);

      // show next parent
      nextParent
        .removeClass(app.htmlClasses.hiddenClass)
        .addClass(app.htmlClasses.visibleClass);

      // focus first input on next parent
      // nextParent.focus();
      window.scrollTo( 0, 0 );

      // activate appropriate step
      app.handleState(nextParent.index());

      // setup signatures
      if (nextParent.attr('id') === 'step-6') {
        this.setupSignatures();
      }

    },

    showPrevStep: function(currentParent,prevParent){

      // hide previous parent
      currentParent
        .addClass(app.htmlClasses.hiddenClass);

      // show next parent
      prevParent
        .removeClass(app.htmlClasses.hiddenClass)
        .addClass(app.htmlClasses.visibleClass);

      // send focus to first input on next parent
      // prevParent.focus();
      window.scrollTo( 0, 0 );

      // activate appropriate step
      app.handleState(prevParent.index());

    },

    validateForm: function(){
      // jquery validate form validation
      this.$form.validate({
        ignore: ":hidden", // any children of hidden desc are ignored
        errorElement: "span", // wrap error elements in span not label
        invalidHandler: function(event, validator){
          $.each(validator.errorList, function(idx,item){
            if(idx === 0){
              $(item.element).focus(); // send focus to first el with error
            }
          })
        },
        submitHandler: function(form) {
          //check for signatures before submission
          if(!$('#signature_image').val()){
              alert("Please provide a your digital signature");
            } else if($('#co_signature').children().length > 0 && !$('#co_signature_image').val()){
              alert("Please provide a co-signer digital signature");
            } else {
            form.submit();
          } 
        }
      });
    },

    checkForValidForm: function(){
      if(this.$form.valid()){
        return true;
      }
    },

    startOver: function(){

      var $parents = this.$formStepParents,
        $firstParent = this.$formStepParents.eq(0),
        $formParent = this.$formParent,
        $stepsParent = this.$stepsParent;

      this.$resetButton.on("click", function(e){

        // hide all parents - show first
        $parents
          .removeClass(app.htmlClasses.visibleClass)
          .addClass(app.htmlClasses.hiddenClass)
          .eq(0).removeClass(app.htmlClasses.hiddenClass)
          .eq(0).addClass(app.htmlClasses.visibleClass);

        // remove edit state if present
        $formParent.removeClass(app.htmlClasses.editFormClass);

        // manage state - set to first item
        app.handleState(0);

        // send focus to first item
        setTimeout(function(){
          $firstParent.focus();
        },200);

      }); // click

    },

    handleState: function(step){

      //remove disbaled once step is reached
      this.$steps.eq(step).attr("disabled", false);
      this.$steps.eq(step).addClass(app.htmlClasses.activeClass);

      // restart scenario
      if(step === 0){
        this.$steps
          .removeClass(app.htmlClasses.activeClass);
        this.$steps.eq(0).addClass(app.htmlClasses.activeClass);
      }
    },

    editForm: function(){
      var $formParent = this.$formParent,
        $formStepParents = this.$formStepParents,
        $stepsParent = this.$stepsParent;

      this.$editButton.on("click",function(){
        $formParent.toggleClass(app.htmlClasses.editFormClass);
        $formStepParents.eq(0).find("input").eq(0).focus();
      });
    },

    killEnterKey: function(){
      $(document).on("keypress", ":input:not(textarea,button)", function(event) {
        return event.keyCode != 13;
      });
    },

    handleStepClicks: function(){

      var $stepTriggers = this.$steps,
        $stepParents = this.$formStepParents;

      $stepTriggers.on("click", function(e){

        e.preventDefault();

        var btnClickedIndex = $(this).index();

        // activate button clicked
        $(this)
          .addClass(app.htmlClasses.activeClass)
          .removeAttr("disabled",false);

        // hide all step parents
        $stepParents
          .removeClass(app.htmlClasses.visibleClass)
          .addClass(app.htmlClasses.hiddenClass);

        // show step that matches index of button
        $stepParents.eq(btnClickedIndex)
          .removeClass(app.htmlClasses.hiddenClass)
          .addClass(app.htmlClasses.visibleClass)
          .focus();

      });

    },

    setupSignatures: function () {
      //if user goes back and clicks signature btn
      $('#signature-btn').on('click',function(e){
        if ($('#spouse_name').val().length && $('#co_signature').children().length === 0) {
          $("#co-signer").removeClass('hidden');
          $("#co_signature").jSignature();
        }
      });
      // check for jsignature element append if one doesnt exist
      if ( $('#signature').children().length === 0 ) {
       $("#signature").jSignature();
      }
      $("#signature").bind('change', function(e){
        $('#signature_image').val($("#signature").jSignature('getData'));
      });

      //only show spouse signature if spouse info present and element doesnt exist
      if ($('#spouse_name').val().length && $('#co_signature').children().length === 0) {
        $("#co-signer").removeClass('hidden');
        $("#co_signature").jSignature();
      } 

      $("#co_signature").bind('change', function(e){
        $('#co_signature_image').val($("#co_signature").jSignature('getData'));
      });

      $(document).on('click', '.btn-clear', function () {
        var sig = $(this).data('sig');
        $("#"+sig).jSignature('reset');
      });
    },

    conditionalRequires: function() {
      $("#spouse_name").on('change', function() {
        let required = $(this).val().length;
        $('#spouse_social_security').prop('required', required);
        $('#spouse_phone').prop('required', required);
        $('#spouse_email').prop('required', required);
        $('#spouse_drivers_license').prop('required', required);
        $('#spouse_drivers_license_state').prop('required', required);
        $('#spouse_dob').prop('required', required);
      });

      $("#company_name").on('change', function() {
        let required = $(this).val().length;
        $('#company_type').prop('required', required);
      });

      $("#home_owner").on('change', function() {
        let required = false;
        if ($(this).val() === 'Yes') {
          required = true;
          $('#home_value').prop('required', required);
          $('#mortgage_balance').prop('required', required);
        } else if ($(this).val() === 'No') {
          $('#home_value').removeProp('required', required);
          $('#home_value').removeClass('error');
          $('#home_value-error').remove();
          $('#mortgage_balance').removeProp('required', required);
          $('#mortgage_balance').removeClass('error');
          $('#mortgage_balance-error').remove();
          $('#home_value').val('');
          $('#mortgage_balance').val('');
        }
      });

      $("#other_loans").on('change', function() {
        let required = false;
        if ($(this).val() === 'Yes') {
          required = true;
        $('#other_balance').prop('required', required);
        } else if ($(this).val() === 'No') {
          $('#other_balance').removeProp('required', required);
          $('#other_balance').removeClass('error');
          $('#other_balance-error').remove();
          $('#other_balance').val('');
        }
      });

      $("#bankrupcy").on('change', function() {
        let required = false;
        if ($(this).val() === 'Yes') {
          required = true;
        $('#bankrupcy_yes').prop('required', required);
        } else if ($(this).val() === 'No') {
          $('#bankrupcy_yes').removeProp('required', required);
          $('#bankrupcy_yes').removeClass('error');
          $('#bankrupcy_yes-error').remove();
          $('#bankrupcy_yes').val('');
        }
      });
    },

    sameAsHomeAddress: function() {
      $('#same_as_home_address').change(function(){
          if($('#same_as_home_address').prop('checked')){
              $('#company_address').val($('#home_address').val() + ' ' + $('#home_address_2').val());
              $('#company_city').val($('#home_city').val());
              $('#company_zip').val($('#home_zip').val());
              var state = $('#home_state option:selected').val();
              $('#company_state option[value=' + state + ']').prop('selected','selected');
          } else { 
              //Clear on uncheck
              $('#company_address').val("");
              $('#company_city').val("");
              $('#company_zip').val("");
              $('#company_state option[value=""]').prop('selected','selected');
          };

      });
    },
  };

  if ($(".multi-step-form").length) {
    app.init();

    $(":input.masked").inputmask();
  }
})(jQuery);
