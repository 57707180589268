// ride.js

(function ($) {

    var Url = require('../../node_modules/domurl/url.js');

    // turn off right click for images
    if (!rc) {
        $('body').on('contextmenu', 'img', function (event) {
            event.preventDefault();
        });
    }

    $('#filter').on('change', function (event) {
        event.preventDefault();
        var selected = $(this).val(),
            location = new Url();

        if (selected) {
            location.query.type = selected;
        } else {
            location.query.type = null;
        }

        location.query.page = null;
        location.query.q = $('#q').val();

        searching();
        window.location = location.toString();
    });

    $('#add_ride_asset').on('submit', function() {
        if ($(this)[0].checkValidity()) {
            $('#add-asset').button('loading');
        }
    });

    $('#ride-form').on('submit', function() {

        if (uploadingImages) {
            alert('Please wait till images have uploaded to save your ride.');
            $('#save-ride').button('reset');
            return false;
        }

        if ($(this)[0].checkValidity()) {

            if ($('.image-list .ride-edit-image').length == 0 && $("input[name='ride[status]']:checked").val() != 'wanted') {
                alert('Please add an image to save this ride');
                return false;
            } else {
                $('#save-ride').button('loading');
            }

        } else {
            $('#save-ride').button('reset');
            return false;
        }

    });

    $('.delete').on('click', function(event) {
        event.preventDefault();

        var answer = confirm('Are you sure you want to delete this ride?');
        if (answer) {
            window.location = $(this).prop('href');
        }
    });


    $('.ajax-delete').on('click', function(event) {
        event.preventDefault();

        var answer = confirm('Are you sure you want to delete this ride?');
        if (answer) {
            $.ajax({
                type: "GET",
                url: $(this).prop('href')
            }).success(function(data) {
                $(".favorites-count").html(`${data.count}`);

                if (data.count >= 1) {
                    $('#favorite-icon').removeClass('.user-ride-no-favorites');
                    $('#favorite-icon').css({ 'color': 'red' });
                } else {
                    $('#favorite-icon').css({ 'color': '#777' });
                }
                $(`#ride-${data.id}`).fadeOut(600, function() {
                    $(this).remove();
                });
            }).fail(function(jqXHR) {
                alert('There was a problem deleting this ride. Please try again. Error: ' + jqXHR.statusText);
            });
        }
    });

    $('.inflate').on('click', function(event) {
        event.preventDefault();

        var answer = confirm('Are you sure you want to add this ride to inflatables?');
        if (answer) {
            window.location = $(this).prop('href');
        }
    });

    $('.ajax-inflate').on('click', function(event) {
        event.preventDefault();

        var answer = confirm('Are you sure you want to add this ride to inflatables?');
        if (answer) {
            $.ajax({
                type: "GET",
                url: $(this).prop('href')
            })
            .success(function(data) {
                $(`#ride-${data.id}`).fadeOut(600, function() {
                    $(this).remove();
                });
            }).fail(function(jqXHR) {
                alert('There was a problem inflating this ride. Please try again. Error: ' + jqXHR.statusText);
            });
        }
    });

    $('.email-owner').on('click', function(event) {
        event.preventDefault();

        // show modal
        $('#ad-email-modal').modal('show');

        var $this = $(this),
            title = $this.data('ridetitle'),
            id = $this.data('rideid'),
            email = $this.data('email');

        var href = "mailto:" + email + "?subject=" + encodeURIComponent("Question about your " + title + ", #" + id) + "&bcc=info@usedrides.com";

        $('#email').html(email);
        $('#subject').html('Question about your ' + title + ', #' + id);
        $('#ride-id').val(id);
        $('#email-link').attr('href', href);

    });

    $("img[data-viewable='false']").on('click', function() {
        $('#login-modal').modal('show');
    });

    $('#send-question').on('submit', function(event) {
        event.preventDefault();

        var $button = $('#send-help-button').button('loading');

        var data = {
            subject: $('#subject').val(),
            message: $('#message').val()
        };

        $.ajax({
            type: "POST",
            url: '/rides/' + $('#ride-id').val() + '/email',
            data: data
        }).success(function() {
            $button.button('reset');
            $('#message').val('');
            $('#ad-email-modal').modal('hide');

        }).fail(function(jqXHR) {
            $button.button('reset');
            alert('There was a problem sending your request. Please try again. Error: ' + jqXHR.statusText);
        });

    });


    $('.image-list').on('click', '.ride-image-delete', function() {
        const asset_id = $(this).data('asset_id');
        const ride_id = $(this).data('ride_id');

        $('#asset-' + asset_id).append('<i class="fa fa-spinner fa-pulse fa-3x"></i>');

        $.ajax({
            type: "GET",
            url: '/rides/' + ride_id + '/destroy_asset/' + asset_id,
        }).success(function(result) {
            $('#asset-' + result.id).remove();

        }).fail(function(jqXHR) {
            alert('There was a problem deleting the image/video. Please try again.');
        });

    });


    $('.image-list').on('click', '.edit-image', function() {
        const image = $(this).data('image');
        window.rideId = $(this).data('ride');
        const assetId = window.assetId = $(this).data('asset');

        featherEditor.launch({
            image: `image-${assetId}`,
            url: image,
            tools: ['enhance', 'orientation', 'crop', 'draw', 'lighting', 'color', 'sharpness', 'focus'],
        });

    });

    $('#search-form').on('submit', function() {
       searching();
    });

    function searching() {
        $('.ride-results').html('<div class="searching"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i></div>');
    }

    $(document).ready(function(){
        //hide price div if call for price checked on page load
        if($('#ride_call_for_price').is(':checked')){
            price_div.style['display'] = 'none';
        }

        //toggle price div on call for price change
        $('#ride_call_for_price').on('change', function(){
            if(this.checked) {
              price_div.style['display'] = 'none';
            } else {
              price_div.style['display'] = 'block';
            }
        });

    });


    //temporary new feature modal
    // $(document).ready(function(){
        // only show on root and first vist
        // if(window.location.pathname === "/" && window.location.search === ""){
        //     var date = new Date();
        //     var y = date.getFullYear();
        //     var m = date.getMonth() + 1;
        //     var d = date.getDate();
        //     var dateYYYYMMDD = '' + y + (m < 10 ? '0' : '') + m + (d < 10 ? '0' : '') + d;
        //     var modalVersion = '20180503';

        //      if (!localStorage.newFeatures || localStorage.newFeatures < modalVersion) { 
                // show new features modal
                // $('#new-feature-modal').modal('show');
                // localStorage.setItem('newFeatures', dateYYYYMMDD);
    //         }  
    //     }
    // });


})(jQuery);
