$('.tommys-private-deals').on('click', ((e) => {
  $('#tommys-private-deals-modal').modal('show');
}))

$('.tommys-private-deals-banner').on('mouseover', ((e) => {
  $('.tommys-private-deals-banner').attr('src','/assets/tommy-wide_hover.png');
}))

$('.tommys-private-deals-banner').on('mouseout', ((e) => {
  $('.tommys-private-deals-banner').attr('src','/assets/tommy-wide.png');
}))