// favorite.js

(function ($) {


    // add to favorites from rides index
    $(document).on('click', '.ajax-rides-favorite', function(event) {
        event.preventDefault();
        var link = $(this);
        $(event.target).addClass("user-ride-favorited");
            $.ajax({
                type: "POST",
                url: $(this).prop('href')
            }).success(function(data) {
                console.log('added favorite');
                $(".favorites-count").html(`${data.count}`);
                
                if(data.count >= 1){
                    $('#favorite-icon').removeClass('.user-ride-no-favorites');
                    $('#favorite-icon').css({'color' : 'red'});
                } else{
                    $('#favorite-icon').css({'color' : '#777'});
                }
                // toggle to remove from rides index
                link.addClass('ajax-rides-favorite-remove');
                link.prop('href', function() {
                    return this.href + '/remove';
                });
                link.removeClass('ajax-rides-favorite');
            }).fail(function(jqXHR) {
                alert('There was a problem adding this ride to favorites. Please try again. Error: ' + jqXHR.statusText);
            });
    });

    // remove from rides index
    $(document).on('click', '.ajax-rides-favorite-remove', function(event) {
        event.preventDefault();
        var link = $(this);
        var answer = confirm('Are you sure you want to remove this ride from favorites?');
        if (answer) {
            
            $(event.target).removeClass("user-ride-favorited");
            $(event.target).addClass("user-ride-favorite");

            $.ajax({
                type: "GET",
                url: $(this).prop('href')
            }).success(function(data) {
                console.log('removed favorite');
                $(".favorites-count").html(`${data.count}`);

                if(data.count >= 1){
                    $('#favorite-icon').removeClass('.user-ride-no-favorites');
                    $('#favorite-icon').css({'color' : 'red'});
                } else{
                    $('#favorite-icon').css({'color' : '#777'});
                }
                
                // toggle so user can add back
                link.removeClass('ajax-rides-favorite-remove');
                link.addClass('ajax-rides-favorite');
                link.prop('href', function() {
                    return this.href.replace('/remove', '');
                });
            }).fail(function(jqXHR) {
                alert('There was a problem removing this favorite. Please try again. Error: ' + jqXHR.statusText);
            });
        }
    });

    // remove from favorites index
    $('.ajax-favorite-remove').on('click', function(event) {
        event.preventDefault();
        var answer = confirm('Are you sure you want to remove this ride from favorites?');
        if (answer) {
            $.ajax({
                type: "GET",
                url: $(this).prop('href')
            }).success(function(data) {
                console.log('removed fav');
                $(".favorites-count").html(`${data.count}`);

                if(data.count >= 1){
                    $('#favorite-icon').removeClass('.user-ride-no-favorites');
                    $('#favorite-icon').css({'color' : 'red'});
                } else{
                    $('#favorite-icon').css({'color' : '#777'});
                }
                

                $(`#ride-${data.id}`).fadeOut(600, function() {
                    $(this).remove();
                });
            }).fail(function(jqXHR) {
                alert('There was a problem removing this favorite. Please try again. Error: ' + jqXHR.statusText);
            });
        }
    });

})(jQuery);
