// faq.js

(function ($) {

    $('.question-text').on('click', function(event) {
        event.preventDefault();

        // hide all
        $('.answer').addClass('hidden');

        // show selected
        $(this).next().removeClass('hidden');

    });


    $('#send-help').on('submit', function(event) {
        event.preventDefault();

        var $button = $('#send-help-button').button('loading');

        var data = {
            email: $('#email').val(),
            subject: $('#subject').val(),
            message: $('#message').val(),
            type: $('#type').val()
        };

        $.ajax({
            type: "POST",
            url: '/faq/email',
            data: data
        }).success(function() {
            $button.remove();
            $('.modal-body').html('Your message has been sent.');

        }).fail(function(jqXHR) {
            $button.button('reset');
            alert('There was a problem sending your request. Please try again. Error: ' + jqXHR.statusText);
        });
    });

})(jQuery);
