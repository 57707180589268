// require('../../node_modules/bootstrap-sass/assets/javascripts/bootstrap/affix');
require('../../node_modules/bootstrap-sass/assets/javascripts/bootstrap/alert');
require('../../node_modules/bootstrap-sass/assets/javascripts/bootstrap/button');
require('../../node_modules/bootstrap-sass/assets/javascripts/bootstrap/carousel');
require('../../node_modules/bootstrap-sass/assets/javascripts/bootstrap/collapse');
// require('../../node_modules/bootstrap-sass/assets/javascripts/bootstrap/dropdown');
require('../../node_modules/bootstrap-sass/assets/javascripts/bootstrap/modal');
// require('../../node_modules/bootstrap-sass/assets/javascripts/bootstrap/popover');
// require('../../node_modules/bootstrap-sass/assets/javascripts/bootstrap/scrollspy');
// require('../../node_modules/bootstrap-sass/assets/javascripts/bootstrap/tab');
// require('../../node_modules/bootstrap-sass/assets/javascripts/bootstrap/tooltip');
// require('../../node_modules/bootstrap-sass/assets/javascripts/bootstrap/transition');

// send csrf in all request
$.ajaxSetup({
    headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
});

require('./modernizr-custom');

require('./ride');
require('./faq');
require('./financing');
require('./ads');
require('./account');
require('./inflatables');
require('./favorite');
require('./tommys-private-deals');

